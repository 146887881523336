
::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
    background-color: transparent;
}
::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
    padding: 12px 9px;
}
.demo-table-expand{
    border-bottom: 1px solid #F3F5F7;
    height: 57px!important;
    padding-left: 260px;
    line-height: 57px;
    position: relative;
    margin: 0!important;
}
.el-table__expanded-cell .demo-table-expand:last-child{
    border-bottom: none
}
.ejbtn1{
    position: absolute;
    right: 250px;
    top: 8px;
}
.ejbtn2{
    position:absolute;
    right: 210px;
    top: 8px;
}
